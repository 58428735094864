import { useState } from 'react'
import './style.scss'
import { questions } from 'src/data'

const FaqElement = ({ title, text, isOpened, onToggle }) => {
    const faqBodyClassNames = 'faq-element__body'
        + (isOpened ? ' faq-element__body--active' : '')

    return (
        <div className='faq-element'>
            <div className='faq-element__header'>
                <p>{title}</p>
                
                <button onClick={onToggle} style={{ backgroundColor: isOpened ? '#104431' : '#C6E8CA' }}>
                    {isOpened ?
                        <svg xmlns='http://www.w3.org/2000/svg' width='12' height='2' viewBox='0 0 12 2' fill='none'>
                            <path d='M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z' fill='white' />
                        </svg>
                        :
                        <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
                            <path fillRule='evenodd' clipRule='evenodd' d='M7 1C7 0.447715 6.55228 0 6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44771 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1Z' fill='#1D694E' />
                        </svg>}
                </button>
            </div>

            <div className={faqBodyClassNames}>
                <p>{text}</p>
            </div>
        </div>
    )
}

export const Faq = () => {
    const [faq, setFaq] = useState([0])

    const isOpened = (id) => {
        return faq.includes(id)
    }

    const toggleQuestion = (id) => {
        return () => {
            if (isOpened(id)) {
                setFaq(faq.filter(q => q !== id))
            } else {
                setFaq(prev => [...prev, id])
            }
        }
    }

    const faqList = questions.map(q => {
        return (
            <FaqElement
                key={q.id}
                onToggle={toggleQuestion(q.id)}
                isOpened={isOpened(q.id)}
                title={q.title}
                text={q.text}
            />
        )
    })

    return (
        <section className='faq'>
            <div className='faq__container'>
                <div className='container section'>

                    <p className='section__sub-title'>Остались вопросы?</p>
                    <h2 id='faq' className='section__title title title--md scroll-mt'>Частые вопросы</h2>

                    <div className='section__body'>
                        <div className='faq__list'>
                            {faqList}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}