import offerPdf from './files/offer.pdf'


const eventSchedules = [
    {
        id: 1, title: '7 сентября', events: [
            { time: '08:00', title: 'Трансфер от м. Саларьево' },
            { time: '10:30', title: 'Завтрак' },
            { time: '11:00', title: 'Игры на знакомство' },
            { time: '13:00', title: 'МК по созданию свечей' },
            { time: '14:00', title: 'Обед' },
            { time: '15:00', title: 'Заселение в домики' },
            { time: '15:30', title: 'Зажигательная Зумба' },
            { time: '16:30', title: 'Расслабляющая растяжка' },
            { time: '18:30', title: 'Body Combat' },
            { time: '19:30', title: 'Ужин' },
            { time: '20:30', title: 'Вечерний костер на лобном месте' },
            { time: '22:00', title: 'Свободное время' },
        ]
    },
    {
        id: 2, title: '8 сентября', events: [
            { time: '08:30', title: 'Подъем' },
            { time: '09:00', title: 'Зарядка' },
            { time: '09:15', title: 'Завтрак' },
            { time: '10:00', title: 'Мастер класс уточняется' },
            { time: '11:00', title: 'DDX Party' },
            { time: '12:00', title: 'DDX Athletics' },
            { time: '13:00', title: 'Обед' },
            { time: '14:00', title: 'Командная игра "100 к 1"' },
            // { time: '14:30', title: 'Обед' },
            { time: '15:00', title: 'Сдача номеров' },
            { time: '16:00', title: 'Трансфер до м. Саларьево' },
        ]
    },
]

export {
    eventSchedules,
    offerPdf,
}