import { CheckBox, Modal, PhoneInput } from 'src/components'
import './style.scss'
import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { validatePhone } from 'src/utils'
import axios from 'axios'
// import { offerPdf } from 'src/data/program'

export const BookTicket = ({ onClose }) => {
    const [isFormSent, setFormSent] = useState(false)
    const [isError, setError] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const handleOverlayClick = (event) => {
        event.stopPropagation()
        onClose()
    }

    const formik = useFormik({
        initialValues: { name: '', phone: '', personalData: true, rim: false },
        validationSchema: Yup.object({
            name: Yup.string().trim().required().min(1).max(100),
            phone: Yup.string().required().test('invalid phone', 'invalid phone', validatePhone),
            personalData: Yup.bool().test('invalid personal data', 'invalid personal data', val => val),
            rim: Yup.bool().test('invalid rim', 'invalid rim', _ => true),
        }),
        onSubmit: async (values, actions) => {
            const data = {
                name: values.name,
                phone: values.phone.replace(/[^\d]/g, ''),
                personalData: values.personalData,
                rim: values.rim,
            }
            setLoading(true)
            
            try {
                const response = await axios.post('https://api.play-ddx.ru/book-fitness-camp', data)
                switch(response.status) {
                    case 200:
                        setFormSent(true)
                        actions.resetForm()
                        break
                    default:
                        setError('При отправке данных произошла ошибка')
                        break
                }
            } catch (err) {
                setError('При отправке данных произошла ошибка')
            } finally {
                setLoading(false)
                setError(false)
            }
        },
    })

    const modalTitle = isFormSent
        ? 'Поздравляем!'
        : 'Забронировать место'

    const isBtnDisabled = Object.keys(formik.errors).length || isLoading
    const btnClassNames = 'book-ticket__btn btn btn--small'
        + (isBtnDisabled ? ' btn--disabled' : '')

    return (
        <div className='overlay' onClick={handleOverlayClick}>
            <div className='overlay__v-scroll'>
                <Modal
                    maxWidth={500}
                    title={modalTitle}
                    onClose={onClose}
                >
                    {isFormSent
                        ? <div className='book-ticket'>
                            <p className='label'>Ваша заявка успешно отправлена, скоро мы с вами свяжемся!</p>
                            <button
                                onClick={onClose}
                                className='book-ticket__btn btn btn--small'
                            >
                                Вернуться назад
                            </button>
                          </div>

                        : <form className='book-ticket' onSubmit={formik.handleSubmit}>
                            <p className='label'>К сожалению места закончились, но вы можете оставить заявку на следующий выезд</p>
                            <div className='book-ticket__inputs'>
                                <fieldset className='fieldset'>
                                    <label className='label label--required'>Имя</label>
                                    <input
                                        className='input'
                                        id='name'
                                        name='name'
                                        type='text' placeholder='Введите имя'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                        disabled={isLoading}
                                        error={String(formik.errors.name && formik.touched.name)}
                                    />
                                </fieldset>

                                <fieldset className='fieldset'>
                                    <label className='label label--required'>Телефон</label>
                                    <PhoneInput
                                        id='phone'
                                        name='phone'
                                        type='text' placeholder='Укажите телефон'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.phone}
                                        disabled={isLoading}
                                        error={String(formik.errors.phone && formik.touched.phone)}
                                    />
                                </fieldset>
                            </div>

                            <div className='book-ticket__checkboxes'>
                                {/* <CheckBox
                                    id='personalData'
                                    name='personalData'
                                    checked={formik.values.personalData}
                                    onClick={() => formik.setFieldValue('personalData', !formik.values.personalData)}
                                    disabled={isLoading}
                                >
                                    Я согласен с публичным <a href='/' target='_blank' rel='noreferrer'>договором-офертой</a> и <a href='https://www.ddxfitness.ru/policy/' target='_blank' rel='noreferrer'>политикой обработки персональных данных</a>
                                </CheckBox> */}
                                <CheckBox
                                    id='personalData'
                                    name='personalData'
                                    checked={formik.values.personalData}
                                    onClick={() => formik.setFieldValue('personalData', !formik.values.personalData)}
                                    disabled={isLoading}
                                >
                                    Я согласен с <a href='https://www.ddxfitness.ru/policy/?utm_source=event&utm_medium=organic&utm_campaign=fitness_camp' target='_blank' rel='noreferrer'>политикой обработки персональных данных</a>
                                </CheckBox>
                                <CheckBox
                                    id='rim'
                                    name='rim'
                                    checked={formik.values.rim}
                                    onClick={() => formik.setFieldValue('rim', !formik.values.rim)}
                                    disabled={isLoading}
                                >
                                    Я согласен на <a href='https://www.ddxfitness.ru/consent-advertising/?utm_source=event&utm_medium=organic&utm_campaign=fitness_camp' target='_blank' rel='noreferrer'>рассылку новостей и предложений DDX Fitness</a>
                                </CheckBox>
                            </div>

                            <button
                                className={btnClassNames}
                                type='submit'
                                disabled={Object.keys(formik.errors).length || isLoading}
                            >
                                Отправить заявку
                            </button>

                            {isError &&
                                <p className='book-ticket__error'>{isError}</p>}

                          </form>}
                </Modal>
            </div>
        </div>
    )
}