import { data } from "src/data"

export const DesktopNavigation = ({ navigationLinks }) => {
    const navigationLinksElement = navigationLinks.map(link => {
        return (
            <a key={link.id} className='link link--500' href={link.href}>{link.title}</a>
        )
    })
    
    return (
        <>
            <div className='header__main-links'>
                {navigationLinksElement}
            </div>
            
            <a
                id='uis-phone-to-replace'
                className='header__phone link link--700'
                href={data.phoneHref}
            >
                {data.phone}
            </a>
        </>
    )
}