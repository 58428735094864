const feedback = [
    { name: 'Ирина Меньшикова', text: 'Организация на высшем уровне, тренера супер отзывчивые😍 чувствуется огромная забота. Все продумали, просто любовь 🫶 тренировки можно потяжелее) ' },
    { name: 'Екатерина Давлетшина', text: 'Все очень понравилось, хорошая организация, очень тёплая атмосфера, забота, все продуманно до мелочей и с любовью<3 было круто, спасибо)' },
    { name: 'Сергей Лугуев', text: 'Все прошло на высшем уровне, огромное спасибо организаторам, за то что сплотили совершенно разных людей в одну команду, и круг моего общения увеличился )' },
    { name: 'Юлия Макаренко', text: 'Прекрасная, позитивная команда организаторов, слаженный коллектив с заботой о гостях, очень довольна что поехала ' },
    { name: 'Елена Захарова', text: 'Организаторы лучшие! Местоположение отлично! Ламповые вечера, много знакомств, все максимально отзывчивые и гости и организаторы!  Лучший кемп в моей жизни я хочу ещё уже прямо сейчас!) ❤❤❤❤' },
    { name: 'Артем Синицын', text: 'Огромное вам спасибо за предоставленную возможность классно отдохнуть в компании замечательных людей!!! ' },
    { name: 'Алексей Куничкин', text: 'В восторге от организации кэмпа, большая благодарность организаторам! Спасибо большое вам за ламповую и камерную атмосферу. ' },
    { name: 'Евгения Прокопенко', text: 'Многого не ожидала от выезда, думала о тренировках и новых знакомствах. У организаторов получилось все, что было задумано, выходные прошли бомбически даже среди незнакомых людей!! ' },
]


export {
    feedback,
}