import { offerPdf } from 'src/data'

const ScheduleItem = ({ title, time }) => {
    return (
        <div className='schedule-desktop__event'>
            {time &&
                <p className='schedule-desktop__event-time'>{time}</p>}
            {title &&
                <p className='schedule-desktop__event-title'>{title}</p>}
        </div>
    )
}

const ScheduleList = ({ data = [] }) => {
    const scheduleElements = data.map(({ title, time }) => {
        return (
            <ScheduleItem
                key={time + title}
                title={title}
                time={time}
            />
        )
    })

    return (
        <div className='schedule-desktop__event-list'>
            {scheduleElements}
        </div>
    )
}

export const ScheduleDesktop = ({ 
    data,
    selectedSchedule,
    isControlEmelentActive,
    handleControlElementClick,
}) => {
    const controlElements = data.map(({ id, title }) => {
        const dataActiveAttr = isControlEmelentActive(id)
            ? 'true'
            : ''

        return (
            <button
                key={id}
                className='schedule-desktop__controls-btn'
                onClick={handleControlElementClick(id)}
                data-active={dataActiveAttr}
            >
                {title}
            </button>
        )
    })

    return (
        <div className='schedule-desktop'>
            <div className='schedule-desktop__controls'>{controlElements}</div>
            <ScheduleList key={selectedSchedule?.id} data={selectedSchedule?.events} />
            <a
                className='schedule-desktop__link'
                href={offerPdf}
                target='_blank'
                rel='noreferrer'
            >Ознакомиться с офертой</a>
        </div>
    )
}