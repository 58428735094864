import './style.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import { useEffect, useRef } from 'react'
import { useIsInViewport } from 'src/hooks'

export const GalleryPreview = ({ 
    id,
    imageOpened,
    slides,
    openImageViewer,
}) => {
    const swiperElement = useRef(null)
    const isIntersected = useIsInViewport(swiperElement)

    useEffect(() => {
        isIntersected
            ? swiperElement.current.swiper.autoplay.start()
            : swiperElement.current.swiper.autoplay.stop()
    }, [isIntersected])

    useEffect(() => {
        if (imageOpened) {
            swiperElement.current.swiper.autoplay.stop()
        } else {
            swiperElement.current.swiper.autoplay.start()
        }
    }, [imageOpened])

    const isVideoPreview = (slide) => slide.type === 'yt-video'

    return (
        <Swiper
            id={id}
            ref={swiperElement}
            slidesPerView='auto'
            spaceBetween={10}
            direction='horizontal'
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            loop={true}
            pagination={{
                clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className='custom-swiper'
        >
            {slides.map((slide, index) => (
                <SwiperSlide key={index} >
                    <img
                        src={slide.preview}
                        onClick={openImageViewer(index)}
                        key={slide.id}
                        alt=''
                    />

                    {isVideoPreview(slide) &&
                        <button className='custom-swiper-play-btn'>
                            <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50' fill='none'>
                                <g filter='url(#filter0_b_843_19)'>
                                    <circle cx='25' cy='25' r='25' fill='#104431' fillOpacity='0.3' />
                                    <circle cx='25' cy='25' r='24' stroke='white' strokeWidth='2' />
                                </g>
                                <path d='M21 31.1614V19.7102C21 18.1182 22.7695 17.1643 24.0994 18.0394L32.5371 23.5915C33.7178 24.3684 33.7421 26.0916 32.5837 26.9014L24.146 32.8005C22.8203 33.7274 21 32.7789 21 31.1614Z' fill='white' />
                                <defs>
                                    <filter id='filter0_b_843_19' x='-40' y='-40' width='130' height='130' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                                        <feFlood floodOpacity='0' result='BackgroundImageFix' />
                                        <feGaussianBlur in='BackgroundImageFix' stdDeviation='20' />
                                        <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_843_19' />
                                        <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_843_19' result='shape' />
                                    </filter>
                                </defs>
                            </svg>
                        </button>}

                </SwiperSlide>))}
        </Swiper>
    )
}