import './style.scss'
import { data } from 'src/data'
import { Logo, SocialLink } from 'src/components'

export const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer__container container'>
                <div className='footer__row'>

                    <a href='#start'>
                        <Logo color='green-white' />
                    </a>

                    <div className='footer__contacts-menu contacts-menu'>
                        <div className='contacts-menu__group'>
                            <p className='contacts-menu__title'>Связаться с организаторами:</p>
                            
                            <div className='contacts-menu__links'>
                                <a
                                    id='uis-phone-to-replace'
                                    className='link link--600'
                                    href={data.phoneHref}
                                >
                                    {data.phone}
                                </a>
                                <a
                                    className='link link--600'
                                    href={data.emailHref}
                                >
                                    {data.email}
                                </a>
                            </div>
                        </div>

                        <div className='contacts-menu__group'>
                            <p className='contacts-menu__title'>Сайт организатора:</p>

                            <div className='contacts-menu__links'>
                                <a
                                    className='link link--600'
                                    href={data.mainSiteHref}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    {data.mainSite}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='footer__social-links'>
                        <SocialLink social='vk' />
                        <SocialLink social='tg' />
                        <SocialLink social='yt' />
                        <SocialLink social='dzen' />
                    </div>

                </div>

                <div className='footer__divider'></div>

                <div className='footer__row'>
                    <p id='rights' className='contacts-menu__title'>© 2024 ООО «ИЛОН» | Ilon, LLC</p>
                    <a
                        href='https://www.ddxfitness.ru/policy/?utm_source=event&utm_medium=organic&utm_campaign=fitness_camp'
                        target='_blank'
                        rel='noreferrer'
                        className='contacts-menu__title'
                    >
                        Соглашение о защите персональных данных
                    </a>
                    <a
                        href='https://www.ddxfitness.ru/terms_of_use/?utm_source=event&utm_medium=organic&utm_campaign=fitness_camp'
                        target='_blank'
                        rel='noreferrer'
                        className='contacts-menu__title'
                    >
                        Пользовательское соглашение
                    </a>
                </div>
            </div>
        </footer>
    )
}