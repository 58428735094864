import './style.scss'
import { Logo } from 'src/components'
import { DesktopNavigation, MobileNavigation } from './components'
import { menuLinksDesktop, menuLinksMobile } from 'src/data'
import { useEffect, useState } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import { disableWindowScroll, enableWindowScroll } from 'src/utils'

// добавить скролл оффсет - DONE
// добавить анимацию на бургер кнопку - DONE
// автоматически закрывать мобильное меню когда экран больше 1200 px - DONE

export const Header = ({
    isIntersected,
    openFormModal,
}) => {
    const isDesktopWidth = useMediaPredicate('(min-width: 1201px)')
    
    const headerClassNames = 'header '
        + (isIntersected ? ' header--active' : '')

    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)

    const handleMobileMenuOpen = () => {
        disableWindowScroll()
        setIsMobileMenuOpened(true)
    }

    const handleMobileMenuClose = () => {
        enableWindowScroll()
        setIsMobileMenuOpened(false)
    }

    useEffect(() => {
        if (isDesktopWidth) {
            handleMobileMenuClose()
        }
    }, [isDesktopWidth])

    const logoColor =
        (isMobileMenuOpened && 'green-orange') ||
        (isIntersected && 'green')
    
    return (
        <header className={headerClassNames}>
            <div className='header__container container'>
                <a onClick={handleMobileMenuClose} href='#start' style={{ lineHeight: 0 }}>
                    <Logo color={logoColor} />
                </a>

                <DesktopNavigation
                    navigationLinks={menuLinksDesktop}
                />

                <MobileNavigation
                    isHeaderActive={isIntersected}
                    navigationLinks={menuLinksMobile}
                    isMobileMenuOpened={isMobileMenuOpened}
                    handleMobileMenuOpen={handleMobileMenuOpen}
                    handleMobileMenuClose={handleMobileMenuClose}
                    openFormModal={openFormModal}
                />
            </div>
        </header>
    )
}