import 'src/styles/index.scss'
import 'src/App.scss'
import { useEffect, useRef, useState } from 'react'
import { Main } from 'src/pages'
import { Footer, Header } from 'src/components'
import { disableWindowScroll, enableWindowScroll } from './utils'
import { BookTicket } from './pages/Main/components'

export const App = () => {
	const intersectionEl = useRef(null)
    const [isIntersected, setIsIntersected] = useState(false)

    useEffect(() => {
        const scrollObserver = new IntersectionObserver((entries) => {
            const newValue = !entries[0].isIntersecting
            setIsIntersected(newValue)
        })
        scrollObserver.observe(intersectionEl.current)
    }, [])

    const [isFormModal, setFormModal] = useState(false)

    const closeFormModal = () => {
        setFormModal(false)
        enableWindowScroll()
    }

    const openFormModal = () => {
        setFormModal(true)
        disableWindowScroll()
    }

	return (
		<div className='app' >
			<div ref={intersectionEl}></div>

            {isFormModal &&
                <BookTicket onClose={closeFormModal} />} 

			<Header isIntersected={isIntersected} openFormModal={openFormModal} />
			<Main openFormModal={openFormModal} />
			<Footer />
		</div>
	)
}