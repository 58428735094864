const PUBLIC_URL = process.env.PUBLIC_URL

// CAMP 02

const prevCamp01Slide01 =  PUBLIC_URL + '/images/prev-camp-2/01.jpg'
const prevCamp01Slide02 =  PUBLIC_URL + '/images/prev-camp-2/02.jpg'
const prevCamp01Slide03 =  PUBLIC_URL + '/images/prev-camp-2/03.jpg'
const prevCamp01Slide04 =  PUBLIC_URL + '/images/prev-camp-2/04.jpg'
const prevCamp01Slide05 =  PUBLIC_URL + '/images/prev-camp-2/05.jpg'
const prevCamp01Slide06 =  PUBLIC_URL + '/images/prev-camp-2/06.jpg'
const prevCamp01Slide07 =  PUBLIC_URL + '/images/prev-camp-2/07.jpg'
const prevCamp01Slide10 =  PUBLIC_URL + '/images/prev-camp-2/10.jpg'
const prevCamp01Slide11 =  PUBLIC_URL + '/images/prev-camp-2/11.jpg'
const prevCamp01Slide12 =  PUBLIC_URL + '/images/prev-camp-2/12.jpg'
const prevCamp01Slide13 =  PUBLIC_URL + '/images/prev-camp-2/13.jpg'

const prevCamp01PreviewSlide01 =  PUBLIC_URL + '/images/prev-camp-2/preview/01.jpg'
const prevCamp01PreviewSlide02 =  PUBLIC_URL + '/images/prev-camp-2/preview/02.jpg'
const prevCamp01PreviewSlide03 =  PUBLIC_URL + '/images/prev-camp-2/preview/03.jpg'
const prevCamp01PreviewSlide04 =  PUBLIC_URL + '/images/prev-camp-2/preview/04.jpg'
const prevCamp01PreviewSlide05 =  PUBLIC_URL + '/images/prev-camp-2/preview/05.jpg'
const prevCamp01PreviewSlide06 =  PUBLIC_URL + '/images/prev-camp-2/preview/06.jpg'
const prevCamp01PreviewSlide07 =  PUBLIC_URL + '/images/prev-camp-2/preview/07.jpg'
const prevCamp01PreviewSlide10 =  PUBLIC_URL + '/images/prev-camp-2/preview/10.jpg'
const prevCamp01PreviewSlide11 =  PUBLIC_URL + '/images/prev-camp-2/preview/11.jpg'
const prevCamp01PreviewSlide12 =  PUBLIC_URL + '/images/prev-camp-2/preview/12.jpg'
const prevCamp01PreviewSlide13 =  PUBLIC_URL + '/images/prev-camp-2/preview/13.jpg'

// CAMP 03

const prevCamp02Slide03 =  PUBLIC_URL + '/images/prev-camp-3/03.jpg'
const prevCamp02Slide04 =  PUBLIC_URL + '/images/prev-camp-3/04.jpg'
const prevCamp02Slide05 =  PUBLIC_URL + '/images/prev-camp-3/05.jpg'
const prevCamp02Slide06 =  PUBLIC_URL + '/images/prev-camp-3/06.jpg'
const prevCamp02Slide07 =  PUBLIC_URL + '/images/prev-camp-3/07.jpg'
const prevCamp02Slide08 =  PUBLIC_URL + '/images/prev-camp-3/08.jpg'
const prevCamp02Slide09 =  PUBLIC_URL + '/images/prev-camp-3/09.jpg'

const prevCamp02PreviewSlide01 =  PUBLIC_URL + '/images/prev-camp-3/preview/01.png'
const prevCamp02PreviewSlide02 =  PUBLIC_URL + '/images/prev-camp-3/preview/02.png'

const prevCamp02PreviewSlide03 =  PUBLIC_URL + '/images/prev-camp-3/preview/03.jpg'
const prevCamp02PreviewSlide04 =  PUBLIC_URL + '/images/prev-camp-3/preview/04.jpg'
const prevCamp02PreviewSlide05 =  PUBLIC_URL + '/images/prev-camp-3/preview/05.jpg'
const prevCamp02PreviewSlide06 =  PUBLIC_URL + '/images/prev-camp-3/preview/06.jpg'
const prevCamp02PreviewSlide07 =  PUBLIC_URL + '/images/prev-camp-3/preview/07.jpg'
const prevCamp02PreviewSlide08 =  PUBLIC_URL + '/images/prev-camp-3/preview/08.jpg'
const prevCamp02PreviewSlide09 =  PUBLIC_URL + '/images/prev-camp-3/preview/09.jpg'


// CAMP 04

const prevCamp04Slide01 =  PUBLIC_URL + '/images/prev-camp-4/01.jpg'
const prevCamp04Slide02 =  PUBLIC_URL + '/images/prev-camp-4/02.jpg'

const prevCamp04PreviewSlide01 =  PUBLIC_URL + '/images/prev-camp-4/preview/01.jpg'
const prevCamp04PreviewSlide02 =  PUBLIC_URL + '/images/prev-camp-4/preview/02.jpg'


const prevCampSlides = [
    { id: 1, src: 'https://www.youtube.com/embed/Scjd10QDE9o?si=6594hYGFq6rVN-FJ',
        preview: prevCamp02PreviewSlide01,  type: 'yt-video' },

    { id: 2, src: 'https://www.youtube.com/embed/H9K9JFNN-TI?si=qcjnDlwx8Iz_a56m',
        preview: prevCamp02PreviewSlide02,  type: 'yt-video' },

    { id: 27, src: prevCamp04Slide01, preview: prevCamp04PreviewSlide01, type: 'img' },
    { id: 28, src: prevCamp04Slide02, preview: prevCamp04PreviewSlide02, type: 'img' },

    { id: 3, src: prevCamp02Slide03, preview: prevCamp02PreviewSlide03, type: 'img' },
    { id: 4, src: prevCamp02Slide04, preview: prevCamp02PreviewSlide04, type: 'img' },
    { id: 5, src: prevCamp02Slide05, preview: prevCamp02PreviewSlide05, type: 'img' },
    { id: 6, src: prevCamp02Slide06, preview: prevCamp02PreviewSlide06, type: 'img' },
    { id: 7, src: prevCamp02Slide07, preview: prevCamp02PreviewSlide07, type: 'img' },
    { id: 8, src: prevCamp02Slide08, preview: prevCamp02PreviewSlide08, type: 'img' },
    { id: 9, src: prevCamp02Slide09, preview: prevCamp02PreviewSlide09, type: 'img' },

    { id: 14, src: prevCamp01Slide01, preview: prevCamp01PreviewSlide01, type: 'img' },
    { id: 15, src: prevCamp01Slide02, preview: prevCamp01PreviewSlide02, type: 'img' },
    { id: 16, src: prevCamp01Slide03, preview: prevCamp01PreviewSlide03, type: 'img' },
    { id: 17, src: prevCamp01Slide04, preview: prevCamp01PreviewSlide04, type: 'img' },
    { id: 18, src: prevCamp01Slide05, preview: prevCamp01PreviewSlide05, type: 'img' },
    { id: 19, src: prevCamp01Slide06, preview: prevCamp01PreviewSlide06, type: 'img' },
    { id: 20, src: prevCamp01Slide07, preview: prevCamp01PreviewSlide07, type: 'img' },
    { id: 23, src: prevCamp01Slide10, preview: prevCamp01PreviewSlide10, type: 'img' },
    { id: 24, src: prevCamp01Slide11, preview: prevCamp01PreviewSlide11, type: 'img' },
    { id: 25, src: prevCamp01Slide12, preview: prevCamp01PreviewSlide12, type: 'img' },
    { id: 26, src: prevCamp01Slide13, preview: prevCamp01PreviewSlide13, type: 'img' },
]

export {
    prevCampSlides,
}