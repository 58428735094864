import './style.scss'

export const Modal = ({ onClose, title, maxWidth = 500, children }) => {
    const stopPropagation = (event) => event.stopPropagation()

    return (
        <div autoFocus onClick={stopPropagation} className='modal' style={{ maxWidth }}>
            <div onClick={onClose} className='modal__close-btn'>
                <svg xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 11 11' fill='none'>
                    <path d='M10 1L1 10M1 1L10 10' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
            </div>

            <h2 className='modal__title'>{title}</h2>

            <div className='modal__body'>
                {children}
            </div>
        </div>
    )
}