import './style.scss'
import { prevCampSlides } from 'src/data'
import { Gallery } from 'src/components'

const SLIDES = [...prevCampSlides]

export const PrevCamp = () => {
    return (
        <section className='how-it-was container section'>
            <p className='section__sub-title'>Галерея</p>
            <h2 id='gallery' className='section__title title title--md scroll-mt'>Как это было?</h2>

            <div className='section__body'>
                <Gallery slides={SLIDES} id='how-it-was-swiper' />
            </div>
        </section>
    )
}