import { useEffect, useState } from "react"

export const useImageOnLoad = (image) => {
    if (!image) {
        throw new Error('You must pass image prop to useImageOnLoad hook.')
    }

    const [
        isLoading,
        setIsLoading
    ] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        const img = new Image()
        img.src = image

        img.onload = () => {
            setIsLoading(false)
        }
    }, [image]) // eslint-disable-line

    return isLoading
}