const menuLinksDesktop = [
    { id: 0, title: 'О мероприятии', href: '#about' },
    { id: 1, title: 'Что вас ждет', href: '#program' },
    { id: 2, title: 'Место проведения', href: '#location' },
    { id: 3, title: 'Галерея', href: '#gallery' },
    { id: 4, title: 'Отзывы', href: '#feedback' },
    { id: 5, title: 'FAQ', href: '#faq' },
]

const menuLinksMobile = [
    { id: 0, title: 'О мероприятии', href: '#about' },
    { id: 1, title: 'Что вас ждет', href: '#program' },
    { id: 2, title: 'Место проведения', href: '#location' },
    { id: 3, title: 'Галерея', href: '#gallery' },
    { id: 4, title: 'Отзывы', href: '#feedback' },
    { id: 5, title: 'FAQ', href: '#faq' },
]


export {
    menuLinksDesktop,
    menuLinksMobile,
}