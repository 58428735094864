import './style.scss'

import { useState } from 'react'
import { disableWindowScroll, enableWindowScroll } from 'src/utils'
import { Modal } from '../Modal'
import { GalleryViewer } from './GalleryViewer'
import { GalleryPreview } from './GalleryPreview'

export const Gallery = ({ slides, id }) => {

    if (!id.length) {
        throw Error('Для работы компонента Gallery должнен быть передан prop id')
    }

    const [imageOpened, setImageOpened] = useState(null)
    const isImageOpened = imageOpened !== null

    const openImageViewer = (index) => () => {
        disableWindowScroll()
        setImageOpened(index)
    }

    const closeImageViewer = () => {
        enableWindowScroll()
        setImageOpened(null)
    }

    const handleOverlayClick = (event) => {
        event.stopPropagation()
        closeImageViewer()
    }
    
    return (
        <>
            {isImageOpened && 
                <div className='overlay' onClick={handleOverlayClick}>
                    <div className='overlay__v-scroll'>
                        <Modal
                            title={'Галерея'}
                            maxWidth={1340}
                            onClose={closeImageViewer}
                        >
                            <GalleryViewer index={imageOpened} slides={slides} />
                        </Modal>
                    </div>
                </div>}

            <GalleryPreview
                id={id}
                imageOpened={imageOpened}
                slides={slides}
                openImageViewer={openImageViewer}
            />
        </>
    )
}