import './style.scss'
import { useEffect, useState } from 'react'
import { data } from 'src/data'
import yandexMark from './images/marker.svg'

export const YandexMap = ({
    coordinates,
    hintContent = '',
    balloonContent = '',
    shouldLoad,
}) => {
    const [isTwoFingerGesture, setIsTwoFingersGesture] = useState(false)
    const [isPinchZoomStarted, setIsPinchZoomStarted] = useState(false)

    const [isMapReady, setMapReady] = useState(false)

    useEffect(() => {
        const createMap = async () => {
            if (!window.ymaps && shouldLoad) {
                try {
                    const response = await fetch(data.ymapsScriptSrc)

                    if (!response.ok) {
                        throw new Error('Failed to load Yandex Maps API');
                    }

                    await new Promise(resolve => {
                        // console.log('loading ymaps')
                        const script = document.createElement('script')
                        script.src = data.ymapsScriptSrc
                        script.type = 'text/javascript'
                        script.async = true
                        script.onload = resolve
                        document.body.appendChild(script)
                    })

                    const ymaps = window.ymaps
                    if (ymaps) {
                        // console.log('creating ymaps')
                        ymaps.ready(() => {
                            const map = new ymaps.Map('map', {
                                center: coordinates,
                                zoom: 16,
                                behaviors: ['drag'], // Включение перемещения карты
                                controls: ['zoomControl']
                            })

                            // Добавьте метку с пользовательской иконкой
                            const placemark = new ymaps.Placemark(coordinates, {
                                hintContent,
                                // balloonContent,
                            }, {
                                iconLayout: 'default#image', // Тип макета иконки
                                iconImageHref: yandexMark, // Путь к изображению иконки
                                iconImageSize: [51, 65], // Размер изображения
                                iconOffset: [-13, -(65 / 2.3)] // Смещение иконки
                            })

                            map.geoObjects.add(placemark)

                            setMapReady(true)
                        })
                    }
                } catch (err) {
                    console.error('Error loading Yandex Maps API:', err)
                }
            }
        }
        createMap()
    }, [shouldLoad]) // eslint-disable-line

    const handleTwoFingerStart = event => {
        if (event.touches.length === 2) {
            setIsTwoFingersGesture(true)
        }
    }

    // Обработчик окончания двухпальцевого жеста
    const handleTwoFingerEnd = event => {
        if (event.touches.length !== 2) {
            setIsTwoFingersGesture(false)
            setIsPinchZoomStarted(false)
        }
    }

    // Обработчик движения при двухпальцевом жесте
    const handleTwoFingerMove = event => {
        if (isTwoFingerGesture) {
            if (!isPinchZoomStarted) {
                setIsPinchZoomStarted(true)
                return
            }

            event.preventDefault()
        }
    }

    return (
        <div
            onTouchStart={handleTwoFingerStart}
            onTouchMove={handleTwoFingerMove}
            onTouchEnd={handleTwoFingerEnd}
            id='map' style={{ width: '100%', height: '100%' }}
        >
            {!isMapReady
                && <div className='custom-y-map-placeholder' />}
        </div>
    )
}