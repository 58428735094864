export const disableWindowScroll = () => {
    const body = document.querySelector('.body')
    document.documentElement.style.overflow = 'hidden'
    body.classList.add('no-touch-scroll')
}

export const enableWindowScroll = () => {
    const body = document.querySelector('.body')
    document.documentElement.style.overflow = 'auto'
    body.classList.remove('no-touch-scroll')
}

export const validatePhone = (val) => {
    const digits = val.replace(/[^\d]/g, '')
    return Boolean(digits.length === 11 
            && !digits.startsWith('77')
            && !digits.startsWith('70')
            && !digits.startsWith('71')
            && !digits.startsWith('72')
            && !digits.startsWith('76')
        )
}