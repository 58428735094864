const PUBLIC_URL = process.env.PUBLIC_URL


const locationSlide02 =  PUBLIC_URL + '/images/location/02.jpg'
const locationSlide03 =  PUBLIC_URL + '/images/location/03.jpg'
const locationSlide04 =  PUBLIC_URL + '/images/location/04.jpg'
const locationSlide05 =  PUBLIC_URL + '/images/location/05.jpg'
const locationSlide06 =  PUBLIC_URL + '/images/location/06.jpg'
const locationSlide07 =  PUBLIC_URL + '/images/location/07.jpg'
const locationSlide08 =  PUBLIC_URL + '/images/location/08.jpg'

const locationSlidePreview02 =  PUBLIC_URL + '/images/location/preview/02.jpg'
const locationSlidePreview03 =  PUBLIC_URL + '/images/location/preview/03.jpg'
const locationSlidePreview04 =  PUBLIC_URL + '/images/location/preview/04.jpg'
const locationSlidePreview05 =  PUBLIC_URL + '/images/location/preview/05.jpg'
const locationSlidePreview06 =  PUBLIC_URL + '/images/location/preview/06.jpg'
const locationSlidePreview07 =  PUBLIC_URL + '/images/location/preview/07.jpg'
const locationSlidePreview08 =  PUBLIC_URL + '/images/location/preview/08.jpg'


const locationSlides = [
    { id: 6, src: locationSlide06, preview: locationSlidePreview06, type: 'img' },
    { id: 7, src: locationSlide07, preview: locationSlidePreview07, type: 'img' },
    { id: 1, src: locationSlide08, preview: locationSlidePreview08, type: 'img' },
    { id: 2, src: locationSlide02, preview: locationSlidePreview02, type: 'img' },
    { id: 3, src: locationSlide03, preview: locationSlidePreview03, type: 'img' },
    { id: 4, src: locationSlide04, preview: locationSlidePreview04, type: 'img' },
    { id: 5, src: locationSlide05, preview: locationSlidePreview05, type: 'img' },
]


export {
    locationSlides,
}