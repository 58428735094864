import { purpleGradientImage } from 'src/data'
import './style.scss'

export const About = () => {
    return (
        <section id='about' className='about section container scroll-mt'>
            {/* <img className='about__purple-gradient' src={purpleGradientImage} alt='' /> */}

            <div className='about__container flex--c-c'>
                {/* <p className='about__title title title--sm'>
                    DDX Fitness GO - <span>твои активные выходные <br />
                    в парк-отеле с уникальной программой </span> <br />
                    от сети фитнес-клубов&nbsp;DDX
                </p> */}
                <p className='about__title title title--sm'>
                    Fitness Camp <span> - твои активные выходные
                    <br /> в парк-отеле с уникальной программой </span>
                    <br /> от сети фитнес-клубов&nbsp;DDX
                </p>
            </div>
        </section>
    )
}