const questions = [
    { id: 0, title: 'Хорошо, а что такое DDX Fitness Camp?', text: 'DDX Fitness Camp — это место, где собираются единомышленники, объединенные атмосферой лагеря и веселья. Это один из самых масштабных проектов DDX Fitness. DDX Fitness Camp — это не про подъем в 7 утра на зарядку, холодную кашу на завтрак и очереди в душ:) Мы детально продумали всю программу! Ты будешь жить в экодоме, вкусно питаться, много проводить времени на свежем воздухе. Короче говоря, все шаблоны долой!'},
    { id: 1, title: 'А что входит в стоимость путевки?', text: 'В стоимость путевки входит проживание в четырехместном, двухместном или одноместном доме (в зависимости от категории билета), 3-х разовое питание, трансфер до отеля и обратно, групповые тренировки, мастер-классы, вечерняя программа у костра, подарки от DDX Fitness. А за дополнительную стоимость можешь посетить сауну, окунуться в чан с холодной водой, взять на прокат велосипед и прокатиться по окрестностям.'},
    { id: 2, title: 'Меня будут заставлять ходить на все мастер классы и тренировки?', text: 'Нет, программа сформирована таким образом, чтобы у тебя была возможность выбора. Можешь сходить на групповую тренировку, можешь посидеть на мастер-классе, ну а если и это тебе не подходит, можешь прогуляться по лесу, дойти до озера, или сходить попариться в сауну. В общем не парься 😊'},
    { id: 3, title: 'Я могу поехать с другом/парой/родственником, который не является членом клуба?', text: 'Конечно! Бери с собой всех, кого давно хочешь затащить на тренировки. И для них мы сделаем скидку на вступительный платёж в размере 100%. Только тссс, пусть это останется секретом.'},
    { id: 4, title: 'А если я хочу поехать один?', text: 'Прекрасно! На нашем выезде мы все знакомимся друг с другом, становимся друзьями, а кто-то даже парой.'},
    { id: 5, title: 'А можно сейчас купить билет, а потом передумать?', text: 'Можно! Если ты сдашь билет не позднее, чем за 14 дней до начала выезда, мы сделаем возврат (но все равно внимательно прочитай оферту перед оплатой).'},
]

export {
    questions,
}