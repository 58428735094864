import './style.scss'

export const CheckBox = ({ checked, onClick, children }) => {
    return (
        <div className='check-box'>
            <svg onClick={onClick} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M7 9.6L9.25 12L13 8'
                    stroke='#ED692A'
                    strokeWidth='2'
                    strokeDasharray='14'
                    strokeDashoffset={checked ? '0' : '14'}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <rect x='0.5' y='0.5' width='19' height='19' rx='5.5' stroke='#568977'/>
            </svg>

            <label style={{ overflowX: 'auto', marginRight: 10 }}>{children}</label>
        </div>
    )
}