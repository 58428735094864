import './style.scss'
import { ProgramEvents, ProgramSchedule } from './components'

export const Program = ({ openFormModal }) => {
    return (
        <section className='program section container'>
            <p className='section__sub-title'>Что вас ждет</p>
            <h2 id='program' className='section__title title title--md scroll-mt'>Программа</h2>

            <div className='program__body section__body'>
                <ProgramEvents />
                <ProgramSchedule openFormModal={openFormModal} />
            </div>
        </section>
    )
}