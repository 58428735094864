import './style.scss'
import { 
    About,
    Hero,
    Location,
    Program,
    PrevCamp,
    FeedBack,
    Faq,
    
    // Events,
} from './components'

export const Main = ({ openFormModal }) => {
    return (
        <div>
            <Hero />
            <About />
            <Program openFormModal={openFormModal} />
            <Location openFormModal={openFormModal} />
            <PrevCamp />
            <FeedBack openFormModal={openFormModal} />
            <Faq />
            
            {/* <Events /> */}
        </div>
    )
}