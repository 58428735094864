import { offerPdf, data as eventData } from 'src/data'

const ScheduleMobileItem = ({
    item,
    isItemOpened,
    handleClick,
}) => {
    const scheduleItemClassNames = (id) => isItemOpened(id)
        ? 'schedule-mobile__item schedule-mobile__item--active'
        : 'schedule-mobile__item'

    const scheduleBtnMobileClassNames = (id) => isItemOpened(id)
        ? 'schedule-mobile__item-btn schedule-mobile__item-btn--active'
        : 'schedule-mobile__item-btn'

    return (
        <div key={item.id} className={scheduleItemClassNames(item.id)}>
            <button onClick={handleClick(item.id)} className={scheduleBtnMobileClassNames(item.id)}>
                <p>{item.title}</p>
                <div>
                    <svg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M2 3L8 9L14 3' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>
                </div>
            </button>

            {isItemOpened(item.id) &&
                <div className='schedule-mobile__item-container'>
                    {item.events.map((scheduleItem, index) => {
                        return (
                            <div key={index} className='schedule-mobile__item-container-item'>
                                <p>{scheduleItem.time}</p>
                                <p>{scheduleItem.title}</p>
                            </div>
                        )
                    })}
                </div>}
        </div>
    )
}

export const ScheduleMobile = ({
    data,
    selectedSchedule,
    isControlEmelentActive,
    handleControlElementClick,
    openFormModal,
 }) => {
    const scheduleMobileElements = data.map(item => {
        return (
            <ScheduleMobileItem
                key={item.id}
                item={item}
                isItemOpened={isControlEmelentActive}
                handleClick={handleControlElementClick}
            />
        )
    })

    return (
        <div className='schedule-mobile'>
            {scheduleMobileElements}

            <a
                className='btn'
                href={eventData.nethouse}
                target='_blank'
                rel='noreferrer'
            >
                Купить билет
            </a>

            {/* <button
                className='btn'
                onClick={openFormModal}
            >
                Купить билет
            </button> */}

            <a
                className='schedule-mobile__link'
                href={offerPdf}
                target='_blank'
                rel='noreferrer'
            >Ознакомиться с офертой</a>
        </div>
    )
}